<template>
    <div style="background-color: white;">
        <div class="cover">
            <!-- <v-img src="../static/rewards-bg.jpg" height="500"></v-img> -->
        </div>
        <v-container style="min-height: 70vh;">
            <v-row class="cards-row">
                <v-col cols="12" sm="4">
                    <div class="icon-round">
                        <img style="transform: translateY(-15px);" src="../static/icons/coin.jpg" width="70%" alt="">
                        <p>What is Fare33 Loyalty program ?</p>
                    </div>
                    <v-card class="mx-auto" color="#f6f6f6" min-height="350px" width="300" elevation="4">
                        <v-card-text class="text-center text--primary cardText">
                            Fare33 understands travelers have choices when it comes to travel. We show our appreciation for all of our customers with low fares and excellent customer service.  For those who wish to register with us, they will have access to our loyalty program. It is designed to reward repeat customers with discounted travel and giveaways!
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <div class="icon-round">
                        <img src="../static/icons/heart.jpg" width="70%" alt="">
                        <p>How to get Loyalty points ?</p>
                    </div>
                    <v-card class="mx-auto" color="#f6f6f6" min-height="350px" width="300" elevation="4">
                        <v-card-text class="text-center text--primary cardText">
                            Clients who register with Fare33, and join our reward programs will be automatically eligible to earn points when they travel with us! All of your bookings will earn you points, based on the cost of your purchase. You will get <br><strong>one point for each 1 USD spent with us</strong>.
                            <v-btn v-if="!$cookies.isKey('userToken')" class="mt-5" @click="$router.push({ name: 'register' })" color="secondary">Register now</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <div class="icon-round">
                        <img src="../static/icons/star.jpg" width="70%" alt="">
                        <p>Redeem</p>
                    </div>
                    <v-card class="mx-auto" color="#f6f6f6" min-height="350px" width="300" elevation="4">
                        <v-card-text class="text-center text--primary cardText">
                            Redeeming your points is just as easy! Simply apply your earned points at checkout and it will be applied as payment! In addition, all of our rewards members will be eligible for our giveaways, which will include free airline tickets and more! <br><strong>100 points = 1 USD</strong>.
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
  title: '-Rewards'
}
</script>
<style scoped>
    .cards-row {
        transform: translateY(-25%);
    }
    .icon-round {
        width: 200px;
        height: 200px;
        border: solid #2F80C2 10px;
        border-radius: 50%;
        margin: 0 auto 30px auto;
        background-color: #f6f6f6;
        text-align: center;
        overflow: hidden;
        position: relative;
    }
    .icon-round p {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
    }
    .cover {
        height: 70vh;
        background-color: teal;
        background-position: bottom;
        background-size: cover;
        background-image: url('../static/rewards.jpg');
    }
    .cardText {
        line-height: 30px;
    }
    @media only screen and (max-width: 600px) {
        .cards-row {
            transform: translateY(0%);
        }
    }
</style>
